import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <header className="page-head no-bottom">
        <h2 className="page-head-title">
          Hello 👋🏻 I'm Antoine, passionate about Entrepreneurship & Personal Growth.
        </h2>
        <h3 class="scroll-text">
          Scroll down to know more about me
        </h3>
      </header>

      <article className="post-content page-template no-image">
        <div className="post-content-body">          
          <div className="flex-content-body">
            <img
              src="https://res.cloudinary.com/drzg8kw0k/image/upload/v1617834763/Portfolio/aub-g-min_a1efds.png">
            </img>
          </div>
          

          <h6>👨🏻‍💻Presentation</h6>
            <p>
              Hi, I'm Antoine. I'm a French <strong>business guy who loves and has technical skills.</strong><br/>
              I work from <strong>3+ years in the Web</strong> from Paris, France on <strong>several positions</strong> such as SEM (SEO & SEA), Web Tracking & Data Quality, Conversion Rate Optimisation, Marketing Automation, Copyrighting & Emailing, ...
            <br/>
            <br/>
            Today, I'm combining all the skills I could learn in a single job : <strong>Growth Hacker.</strong><br/>
              My goal is to generate growth for companies <em>(essentially start-ups who need to become big, really fast)</em>.

            </p>


          <h6>🎓 Education</h6>

          <p>
              I know since I'm 15 that I'm <strong>fascinated about successful entrepreneurs and so, business</strong> in general.
              Just after my "Baccalaureat" <em>(french equivalent to A-level)</em>, I decided to integrate a business school to work in finance <em>(2014)</em>.
            <br/>
            <br/>
            As I didn't enjoy it, I <strong>started to learn by myself how to create a website</strong>, in order to create my online business. 
            Once bachelor graduated, I integrated the 6th French business school in a Master specialized in Digital Marketing and
            I discovered that I didn't know so much about the web and internet industry <em>(2017)</em>.
            <br/>
            <br/>
            During my first 3 working years in the web, I faced some technical issues by my lack of knowledge on programming skills.
            So <strong>in parallel of my freelancing activity, I learn Front / SQL / Ruby programming languages</strong> and got my bachelor in Computer Science <em>(2021)</em>.
            <br/>
          </p>

          <h6>🃏 Hobbies</h6>

          <p>
            🌱 Personnal Growth, Self Improvement, Productivity<br/>
            🥋 Martial Arts, Tennis, Workout & sport with friends<br/>
            💼 Business, Web & Entrepreneurs Success<br/>
            🎶 Listening to Rap, R&B, Jazz and Lo-Fi<br/>
            ⏱ Time Management, Automation<br/>
            📟 Programming<br/>
          </p>
          {/* <p>
            I started my career in Finance.
          </p> */}
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
